import BreveStudioLogo from './images/BreveStudioLogo.png';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
// import KissCanvasSketch from './components/CanvasComponents/KissCanvasText';

function App() {
  return (
    <>
      <div id="App">
        <div className="soon">COMING SOON · 2025</div>
        <div className="App-breve-logo">
          <img className="breve-logo" alt='breve logo' src={BreveStudioLogo} />
        </div>
        <div className="mad-spain">Madrid - Spain</div>
      </div>
      {/* <BrowserRouter>
        <Routes>
          <Route path='/kiss' element={<KissCanvasSketch />} />
        </Routes>
      </BrowserRouter> */}
    </>
  );
}

export default App;
